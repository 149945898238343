import { useEffect } from "react";
import styled from "styled-components";
import { Color, Font, rem, responsive } from "../../utils/style";

// Constants
import { ActionType } from "../../constants/product";

// Services
import intl from "../../services/intl";

// Components
import MagicLink from "../MagicLink";
import Text from "../Text";
import BundleRibbon from "../bundle/BundleRibbon";
import UpsellItems from "./UpsellItems";

// Utils
import metrics from "../../utils/metrics";
import { getPromotionDetails } from "../../utils/promotion";
import { trackUpsell } from "../../utils/tracking/cart";

// Hooks
import useBundlePromotion from "../../hooks/discount/useBundlePromotion";

// Styled Elements
const UpsellContainer = styled.div.attrs({
  role: "section",
  "aria-labelledby": "upsell-heading",
})`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  margin-top: 32px;

  ${responsive.md`
    align-items: center;
    margin-top: 64px;
  `}
`;

const UpsellHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  margin-bottom: 16px;

  ${responsive.md`
    align-items: center;
    margin-bottom: 32px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${Color.fadedGrey};
  `}
`;

const UpsellHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  h2 {
    margin: 0;
    margin-right: 16px;
    font-weight: 500;

    font-size: ${rem(16)};
    line-height: ${rem(26)};

    ${responsive.md`
      font-size: ${rem(20)};
      line-height: ${rem(30)};
    `}
  }
`;

const UpsellSubheading = styled.div`
  p {
    margin: 0;
    ${Font.dutch};
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  }
`;

const ShopAllLink = styled.div`
  p {
    margin: 0;
    ${Font.dutch};
    letter-spacing: 0px;
    font-size: ${rem(14)};
    line-height: ${rem(24)};

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    `}
  }

  a {
    border-bottom: 1px solid ${Color.ritualBlue};
  }
`;

/**
 * @component Upsell - Renders a list of quick add cards
 *
 * @param {Array} contentfulProducts - Array of contentful product objects
 * @param {Number} cartQuantity -  Quantity of products in cart
 * @param {Boolean} isProcessing - Determines if cart is processing a change
 * @param {Array} upsellSkus - Array of SKU strings to display
 */
export default function Upsell(props) {
  const { isProcessing, upsellSkus, cartQuantity, contentfulProducts } = props;
  const promotion = useBundlePromotion(ActionType.UpsellAdd, 1);

  useEffect(() => {
    if (upsellSkus && upsellSkus.length > 0) trackUpsell(upsellSkus);
  }, [upsellSkus]);

  function handleShopAllClick() {
    metrics.track("CTA Clicked", {
      location: "Cart Upsell",
      title: "Shop All",
    });
  }

  const title = promotion
    ? intl.t("cart.upsell.title", "It pays to bundle")
    : intl.t("cart.upsell.title-quantity", "Make us a household name");

  return (
    <UpsellContainer>
      <UpsellHeader>
        <UpsellHeading>
          <h2 id="upsell-heading">{title}</h2>
          <BundleRibbon
            actionType={ActionType.UpsellAdd}
            quantity={1}
            short={true}
          />
        </UpsellHeading>
        <UpsellSubheading>
          <p>
            {promotion
              ? intl.t(
                  "cart.upsell.upsell-subheading",
                  `Get {copy} off your first order when you bundle 2 or more products.`,
                  { copy: getPromotionDetails(promotion).formattedValue },
                )
              : intl.t(
                  "cart.upsell.upsell-subheading-quantity",
                  `Create a bundle that’s right for your home and help turn healthy habits into a shared Ritual.`,
                )}
          </p>
        </UpsellSubheading>
      </UpsellHeader>

      <UpsellItems
        isProcessing={isProcessing}
        upsellSkus={upsellSkus}
        cartQuantity={cartQuantity}
        contentfulProducts={contentfulProducts}
      />

      <ShopAllLink>
        <p>
          <Text
            id="cart.upsell.shop-copy"
            defaultMessage="Looking for something else?"
          />{" "}
          <MagicLink
            to="/shop/all"
            title="Shop the full lineup"
            onClick={handleShopAllClick}
          >
            <Text id="cart.upsell.shop-all" defaultMessage="Shop All" />
          </MagicLink>
        </p>
      </ShopAllLink>
    </UpsellContainer>
  );
}
